import { createContext, useContext, useEffect, useState } from "react";
import axiosClient from "../utils/axios";

const AuthContext = createContext<AuthProps>(null);

export const useAuth = (): AuthProps => {
  return useContext(AuthContext);
};

type LoginStatus = "LOGGED_IN" | "LOGGED_OUT" | "UNKNOWN";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<User>(null);
  const [token, setToken] = useState<string>(null);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>("UNKNOWN");

  const login = async (email: string, password: string) => {
    const res = await axiosClient.post("/auth/admin/login", {
      userName: email,
      password
    });

    if (res.status === 200) {
      localStorage.setItem("am_token", res.data.token);

      localStorage.setItem("am_user", JSON.stringify(res.data.user));
      setToken(res.data.token);
      setUser(res.data.user);
      setLoginStatus("LOGGED_IN");
    }

    const response = await axiosClient.get("/admin/me");
    // console.log(response);

    return true;
  };

  const sendFCMToken = async (token = null) => {
    await axiosClient.post("/auth/fcm-token", {
      fcmToken: token || localStorage.getItem("fcm_token")
    });
  };

  const getPermissions = () => {
    const user = JSON.parse(localStorage.getItem("am_user"));
    let perms = user.permissions?.map((p) => {
      return p.permission;
    }) || [];
    return perms;
  };

  const logout = () => {
    localStorage.removeItem("am_token");
    localStorage.removeItem("am_user");
    setToken(null);
    setUser(null);
    setLoginStatus("LOGGED_OUT");
  };

  useEffect(() => {
    if (localStorage.getItem("am_token") != null) {
      setToken(localStorage.getItem("am_token"));
    }

    if (localStorage.getItem("am_user") != null) {
      setUser(JSON.parse(localStorage.getItem("am_user")));
      setLoginStatus("LOGGED_IN");
    } else {
      setLoginStatus("LOGGED_OUT");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token,
        loginStatus,
        getPermissions
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthProps {
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  user: User;
  token: string;
  loginStatus: LoginStatus;
  getPermissions: () => string[];
}

interface User {
  id: string;
  name: string;
  email: string;
  type: string;
}
