import { useToast } from "@chakra-ui/react";

export default function useSmartToast() : SmartToastProps {
  const toast = useToast();

  const success = (description: string) => {
    toast({
      title: 'Success',
      description,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  }

  const error = (error, defaultMessage: string) => {
    toast({
      title: "Error",
      description: error?.response?.data?.message || defaultMessage || "An error occurred.",
      status: "error",
      duration: 5000,
      isClosable: true,
    })
  }

  return {
    success,
    error
  }
}

interface SmartToastProps {
  success: (message: string) => void;
  error: (error, defaultMessage: string) => void;
}