import axios from "axios";

const getAuthToken = () => {
	if (typeof window !== "undefined" && localStorage.getItem("am_token")) {
		return localStorage.getItem("am_token");
	}
	return undefined;
};

export const BASE_URL = process.env.NEXT_PUBLIC_AM_BACKEND_URL || "http://localhost:3000/api"; 

const axiosClient = axios.create({
	baseURL: BASE_URL,
});

axiosClient.interceptors.request.use((config) => {
	const token = getAuthToken();
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
});

export default axiosClient;
