import React, { ReactNode, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";

import Sidebar from "./Sidebar";
import MobileNav from "./MobileNav";
import { useAuth } from "../../hooks/AuthContext";
import { useRouter } from "next/router";
import FullPageLoader from "./FullPageLoader";
import BackButton from "./BackButton";

export default function Layout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loginStatus } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (loginStatus === "LOGGED_OUT") {
      router.push("/login");
    }
  }, [loginStatus]);

  if (loginStatus === "UNKNOWN" || loginStatus === "LOGGED_OUT") {
    return <FullPageLoader />;
  }

  return (
    <Box minH="100vh" bg="#FFF" overflowX={"hidden"}>
      <Sidebar isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <MobileNav onOpen={onOpen} />
      <Stack id="page" zIndex={10} >
        <BackButton />
        <Box
          ml={{ base: 0, md: 64 }}
          px={{ base: "4", md: "8" }}
          pt={`${router.pathname === "/" ? "24  " : "5"}`}
          // overflowX={"hidden"}
        >
          {children}
        </Box>
      </Stack>
    </Box>
  );
}
