import {
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Avatar,
  Badge,
  Box
} from "@chakra-ui/react";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FiBell, FiUser } from "react-icons/fi";
import { TbTransfer } from "react-icons/tb";
import {
  getAllNotifications,
  getLastSeen,
  setNotificationLastSeen
} from "../../controllers/notification.controller";
import { FaExclamation } from "react-icons/fa";
import useSmartToast from "../../hooks/SmartToast";

export default function NotificationsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notifications, setNotifications] = useState([]);
  const [lastSeen, setLastSeen] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const toast = useSmartToast()

  const fetchNotifications = async () => {
    try {
      const data = await getAllNotifications();
      // console.log(data);
      setNotifications(data);
    } catch (error) {
      console.log(error);
      // toast.error(error, "An error occurred while fetching notifications")
    }
  };

  const setLastSeenServer = async () => {
    try {
      await setNotificationLastSeen();
    } catch (error) {
      console.log(error);
    }
  };

  const getLastSeenServer = async () => {
    try {
      const data = await getLastSeen();
      setLastSeen(new Date(data.lastSeen));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLastSeenServer();
    fetchNotifications();

    const interval = setInterval(async () => {
      try {
        const data = await getAllNotifications();
        // console.log(data);
        setNotifications(data);
      } catch (error) {
        console.log(error);
        clearInterval(interval)
        // toast.error(error, "An error occurred while fetching notifications")
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const notificationCount =
      notifications?.filter((notification) => {
        return moment(notification.createdAt).isAfter(lastSeen);
      }).length || 0;
    // console.log(notificationCount);

    setNotificationCount(notificationCount);
  }, [notifications]);

  return (
    <Box position="relative">
      <IconButton
        icon={<FiBell />}
        variant="ghost"
        aria-label="Notifications"
        colorScheme="gray"
        display={{ base: "none", md: "flex" }}
        onClick={onOpen}
      />
      {notificationCount > 0 && (
        <Badge
          colorScheme="orange"
          rounded="full"
          position="absolute"
          bottom="0"
          right="0"
          pointerEvents="none"
        >
          {notificationCount > 9 ? "9+" : notificationCount}
        </Badge>
      )}
      <NotificationsDrawer
        isOpen={isOpen}
        onClose={() => {
          setLastSeen(new Date());
          setLastSeenServer();
          onClose();
        }}
        notifications={notifications}
        lastSeen={lastSeen}
      />
    </Box>
  );
}

function NotificationsDrawer({ isOpen, onClose, notifications, lastSeen }) {
  // console.log(lastSeen);
  const seenNotifications = notifications?.filter((notification) =>
    moment(notification.createdAt).isSameOrBefore(lastSeen)
  );
  const unseenNotifications = notifications?.filter((notification) =>
    moment(notification.createdAt).isAfter(lastSeen)
  );

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay />
      <DrawerContent roundedLeft="2xl">
        <DrawerCloseButton p="4" m="4" />
        <DrawerHeader borderBottom="solid 1px" borderBottomColor="gray.200">
          <HStack px="2" py="2" spacing="4">
            <FiBell />
            <Text>Notifications</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody px="0" h="full">
          <Stack h="full" overflowY="auto" overflowX="hidden" spacing="0">
            {unseenNotifications.length > 0 && (
              <Stack>
                <Text
                  fontSize="xs"
                  fontWeight="semibold"
                  color="gray.500"
                  px="4"
                  mt="3"
                  mb="2"
                  letterSpacing="wider"
                >
                  UNSEEN NOTIFICATIONS
                </Text>
                {unseenNotifications?.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                ))}
              </Stack>
            )}
            {seenNotifications.length > 0 && (
              <Stack>
                <Text
                  fontSize="xs"
                  fontWeight="semibold"
                  color="gray.500"
                  px="4"
                  mt="3"
                  mb="2"
                  letterSpacing="wider"
                >
                  OLDER NOTIFICATIONS
                </Text>
                {seenNotifications?.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function NotificationItem({ notification, isOpen, onClose }) {
  // const title = 'New Quotation';
  // const description = 'You have a new quotation from John Doe';
  // const time = '2 hours ago';
  
  const type = notification.type.split("_")[2]?.toLowerCase();
  const router = useRouter();

  return (
    <HStack
      spacing="4"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      px="4"
      py="2"
      _hover={{ bg: "gray.100" }}
      transition={"all 0.25s"}
      w="full"
      onClick={() => {
        router.push(notification.actionLink);
        onClose();
      }}
    >
      <Avatar
        size="sm"
        bg={
          type === "in"
            ? "yellow.300"
            : type === "out" || type === "ready"
            ? "green.500"
            : type === "litigation"
            ? "red.500"
            : ""
        }
        icon={(type==="ready")?<FaExclamation fontSize="1rem"/>:<TbTransfer fontSize="1rem" />}
      />
      <Stack spacing="0.5" w="full">
        <HStack spacing="2">
          <Text fontWeight="medium" fontSize="sm" color="gray.800">
            {notification.subject}
          </Text>
          <Text fontSize="xs" color="gray.500" ml="auto">
            {moment(notification.createdAt).fromNow()}
          </Text>
        </HStack>
        <Text fontSize="sm" color="gray.600">
          {notification.message}
        </Text>
      </Stack>
    </HStack>
  );
}
