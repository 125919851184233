import axiosClient from "../utils/axios"

export const getAllNotifications = async () => {
  const {data} = await axiosClient.get("/system-notification/list-all")
  return data
}

export const getLastSeen = async () => {
  const {data} = await axiosClient.get("/system-notification/last-seen")
  return data
}

export const setNotificationLastSeen = async () => {
  const {data} = await axiosClient.post("/system-notification/")
  return data
}