import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FaChevronLeft } from "react-icons/fa";

export default function BackButton() {
  const router = useRouter();
  if(router.pathname === "/") return null;
  return (
    <Flex 
      ml={{ base: 0, md: 64 }}
      mt={{ base: 8 }}
      px={{ base: 4, md: 8 }}
      width={{ base: "100%", md: "calc(100% - 15rem)" }}
      zIndex={10}
      h={8}
      pointerEvents={"none"}
    >
      <HStack
        spacing={2}
        pointerEvents={"all"}
        position={"fixed"}
        onClick={() => {
          router.back();
        }}
        _hover={{
          cursor: 'pointer',
          // bg: 'gray.50',
          borderColor: 'gray.400'
        }}
      >
        <FaChevronLeft />
        <Text>Back</Text>
      </HStack>
    </Flex>
  );
}
