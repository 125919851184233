import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';
import {
  FiBell,
  FiChevronDown,
  FiGrid,
  FiLogOut,
  FiMenu,
  FiSearch,
  FiShoppingBag,
} from 'react-icons/fi';
import { useAuth } from '../../hooks/AuthContext';
import Link from 'next/link';
import { FaFileInvoice, FaMoneyBill, FaMoneyBillAlt } from 'react-icons/fa';
import NotificationsButton from './NotificationsButton';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export default function MobileNav({ onOpen, ...rest }: MobileProps) {
  const { user, logout } = useAuth();

  return (
    <Flex
      ml={{ base: 0, md: 64 }}
      px={{ base: 4, md: 4 }}
      zIndex={10}
      height="20"
      alignItems="center"
      borderBottomWidth="0px"
      bgColor={useColorModeValue('white', 'gray.800')}
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between' }}
      position="fixed"
      top="0"
      // zIndex="50"
      width={{base: '100%', md: 'calc(100% - 15rem)'}}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <HStack>
        <Box display={{ base: 'flex', md: 'none' }} >
          <a href="https://advancemobility.io" target="_blank" rel="noreferrer noopener">
            <Image src='/images/brand-logo.png' w='32' />
            {process.env.NEXT_PUBLIC_NODE_ENV !== "production" && (<span  style={{
						position: "fixed",
						fontSize: "10px",
						paddingTop: "4px",
						paddingBottom: "2px",
						paddingLeft: "5px",
						paddingRight: "5px",
						backgroundColor: "#56cc8e",
						borderRadius: "10px",
						marginLeft: "7.7rem",
						marginTop: "-0.4rem",
						color: "white",
					}}>DEV</span>)}
          </a>
        </Box>
      </HStack>

      <HStack spacing={{ base: '0', md: '2' }}>
        <Flex alignItems={'center'} pl='4' >
          <NotificationsButton />
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.name)}&background=random`
                  }
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              py='0'
              overflow='hidden'
            >
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="1px" 
                px='4'
                py='3'
                borderBottom='solid 1px'
                borderColor='gray.200'
              >
                <Text fontSize="sm" color='gray.800' fontWeight='semibold'>{user?.name}</Text>
                <Text fontSize="xs" color="gray.600">{user?.email}</Text>
              </VStack>
              <MenuItem onClick={logout} px='4' py='3' fontWeight='medium' fontSize='sm' icon={<FiLogOut />}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};