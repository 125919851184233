import { ChakraProvider, LightMode, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import '../styles/globals.css'
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme
import Layout from '../components/common/Layout'
import { AuthProvider } from '../hooks/AuthContext'
import Head from 'next/head'
import { MultiSelectTheme } from 'chakra-multiselect'

import { inputAnatomy, cardAnatomy } from "@chakra-ui/anatomy"
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system"
import { useRouter } from 'next/router';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)
const cardStyler = createMultiStyleConfigHelpers(cardAnatomy.keys)

const theme = extendTheme(
  {
    components: {
      MultiSelect: MultiSelectTheme,
      Card: cardStyler.defineMultiStyleConfig({
        baseStyle: cardStyler.definePartsStyle({
          container: {
            boxShadow: 'none',
            px: '6',
            py: '4',
            rounded: 'md',
            border: 'solid 1px var(--chakra-colors-gray-200)'
          }
        })
      }),
      FormLabel: {
        baseStyle: {
          fontSize: 'sm',
          fontWeight: 'medium',
          color: 'gray.600',
        }
      },
      Button: {
        baseStyle: {
          borderRadius: 'md',
        },
        variants: {
          am: {
            backgroundColor: 'black',
            color: 'white',
            transition: 'all 0.25s',
            rounded: 'md',
            fontSize: 'base',
            _hover: {
              backgroundColor: 'gray.800',
              transform: 'scale(1.025)',
              shadow: 'lg',
              _disabled: {
                color: 'white',
                backgroundColor: 'black',
                transform: 'scale(1)'
              }
            },
            _active: {
              backgroundColor: 'black',
              color: 'white',
              transform: 'scale(0.975)',
              _disabled: {
                color: 'white',
                backgroundColor: 'black',
                transform: 'scale(1)'
              }
            },
            _focus: {
              ringColor: 'rgba(13, 8, 77, 0.5)',
              ring: '2px',
              ringOffset: '2px',
            }
          }
        }
      },
      Input: {
        baseStyle: definePartsStyle({
          field: {
            borderRadius: 'md',
            fontSize: 'base',
            fontWeight: 'medium',
            _focus: {
              borderColor: 'black.400',
              boxShadow: 'none'
            },
            _placeholder: {
              color: 'gray.500'
            }
          },
          element: {
            color: 'gray.500',
            _focus: {
              color: 'black.400',
            }
          }
        }),
        defaultProps: {
          focusBorderColor: 'black.400',
        },
      },
      Tabs: {
        defaultProps: {
          fontSize: 'xs',
        }
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: '2xl',
          }
        }
      }
    },
    colors: {
      'procura-blue': '#0d084d',
    }
  },
  withDefaultColorScheme({ colorScheme: 'gray', components: ['Input', 'Button', 'Checkbox'] })
)

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => {
    return (
      <Layout>
        {page}
      </Layout>
    )
  })

  const router = useRouter()

  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Head>
          <title>Advance Mobility</title>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <LightMode>
          {getLayout(<Component {...pageProps} />)}
        </LightMode>
      </ChakraProvider>
    </AuthProvider>
  )
}

export default MyApp
